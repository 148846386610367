import React from "react"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import HeroBg from "../components/Image/IndustryHeroBg"
import {
  Section,
  Flex,
  FlexTwoCol,
  BreadCrumb,
  Background,
  MarkdownContent,
  SectionPageTitle,
  Container,
} from "../components/SectionTags"
import CallToAction from "../components/CallToAction"
import Process from "../components/Process"
import AboutPartnerBrand from "../sections/about/AboutPartnerBrand"
import SearchCTA from "../components/CallToAction/SearchCTA"
import Content from "../components/Content"
import RepairContactForm from "../components/RepairContactForm"
import BreakpointUp from "../components/Media/BreakpointUp"
import RequestQuote from "../components/RequestQuote"
//import Marquee from "../components/Marquee"


const ProductMedia = styled.div`
  text-align:left;
  margin-bottom: 30px;
  ${BreakpointUp.md`
    text-align:right;
    margin-bottom: 0;
  `}
`
const ProcessSection = styled.div`
  position: relative;
  background-color: #edf8ff;
`
class RepairTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenRequest: false,
    }
    this.openRequestQuote = this.openRequestQuote.bind(this)
    this.closeRequestQuote = this.closeRequestQuote.bind(this)
  }
  openRequestQuote() {
    this.setState({
      isOpenRequest: true,
    })
    //document.body.style.height = "100vh"
    document.querySelector("body").classList.add("modal-open")
  }
  closeRequestQuote() {
    this.setState({
      isOpenRequest: false,
    })
    //document.body.style.height = "unset"
    document.querySelector("body").classList.remove("modal-open")
  }
  render() {
    const { isOpenRequest } = this.state
    const pageData = this.props.data.contentfulServiceSubRepair
    const ctaData = pageData.ctaSection
    const processData = pageData.processSection
    const AllPartners = this.props.data.allContentfulPartner
    const AllBrands = this.props.data.allContentfulBrand
    const htmlData = pageData.content
    const contentVideo = pageData.contentVideoUrl
    const temp = pageData.service.serviceName
      .replace(/[^\w\s]+/gi, " ")
      .replace(/  +/g, " ")
    const serviceUrl = temp.split(" ").join("-").toLowerCase()

    const location = this.props.location
    const currentPageUrl = location.pathname;
    const page1Url = '/ac-vs-dc-motors-which-is-right-for-you';
    const page2Url = '/bearing-fluting-what-is-it-how-is-it-caused';
    const page3Url = '/options-for-motors-in-wet-environments';
    const page4Url = '/do-you-need-a-general-purpose-motor-or-an-inverter-duty-motor';
    const hideSection = currentPageUrl === page1Url || currentPageUrl === page2Url || currentPageUrl === page3Url || currentPageUrl === page4Url;
   
    //console.log('Hide section', hideSection);

    return (
      <Layout>
        <Seo title={pageData.metaTitle}
          description={pageData.metaDescription.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />        
          {/* {hideSection && (
          <Marquee />
          )} */}
        </div>
        <RequestQuote
          display={isOpenRequest ? "block" : "none"}
          onClose={this.closeRequestQuote}
        />
        <Section xpt="130px" xpb="60px" mpt="130px" mpb="60px" pt="100px" pb="30px" bgColor="#EDF8FF">
          <Background width="65%" left="0"><HeroBg /></Background>
          <Container maxWidth="1100px">
            <BreadCrumb>
              <Link to="/">
                <span>Home</span>
              </Link>
              <Link to={`/${serviceUrl}`}>
                <span>{pageData.service.serviceName}</span>
              </Link>{" "}
              {pageData.name}
            </BreadCrumb>
            <Flex alignItem="center" justifyContent="space-between">
              <FlexTwoCol flexWidth="58.333333%" flexMWidth="58.333333%">
                <SectionPageTitle>{pageData.name}</SectionPageTitle>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.description.childMarkdownRemark.html,
                  }}
                />
              </FlexTwoCol>
              <FlexTwoCol flexWidth="33.333333%" flexMWidth="33.333333%">
                <ProductMedia>
                  <GatsbyImage image={getImage(pageData.thumbnail)} alt={pageData.name} />
                </ProductMedia>
              </FlexTwoCol>
            </Flex>
          </Container>
        </Section>
        <SearchCTA
          title={pageData.searchSectionTitle}
          serviceName={pageData.service.serviceName}
        />
        {!hideSection && (
          <>
            <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px">
              <Background right="0" bottom="0" width="50%" maxHeight="100%" zIndex="-1">
                <HeroBg />
              </Background>
              <Container maxWidth="1100px">
                <RepairContactForm />
              </Container>
            </Section>
            <CallToAction data={ctaData} />
            <ProcessSection>
              <Background right="0" bottom="0" width="50%" maxHeight="100%"> 
                <HeroBg />
              </Background>
              <Process data={processData}  openRequest={this.openRequestQuote} />
            </ProcessSection>
            <AboutPartnerBrand
              title={pageData.partnerSectionTitle}
              partners={AllPartners}
              brands={AllBrands}
            />   
          </>
        )}    
        {htmlData && <Content data={htmlData} video={contentVideo} />}
      </Layout>
    )
  }
}

export default RepairTemplate

export const pageQuery = graphql`
  query repairPageQuery($id: String!) {
    contentfulServiceSubRepair(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      name
      url
      description {
        childMarkdownRemark {
          html
        }
      }
      service {
        serviceName
      }
      thumbnail {
        gatsbyImageData(quality: 100)
      }
      contactSectionTitle
      ctaSection {
        label
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      processSection {
        title
        secondTitle
        features {
          process
          description
        }
      }
      partnerSectionTitle
      searchSectionTitle
      content {
        childMarkdownRemark {
          html
        }
      }
      contentVideoUrl
    }
    allContentfulPartner(sort: { fields: order }) {
      edges {
        node {
          url
          logo {
            gatsbyImageData(quality: 100)
          }
          name
          logoWidth
        }
      }
    }
    allContentfulBrand(sort: { fields: order }) {
      edges {
        node {
          url
          image {
            gatsbyImageData(quality: 100)
          }
          title
          imageWidth
        }
      }
    }
  }
`
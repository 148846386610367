import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

export const ProcessWrap = styled.div`
  display: flex;
  position:relative;
  z-index:1;
  & svg {
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 40px;
    padding: 5px;
    height: 40px;
    background: white;
  }
  @media (max-width: 1199px) {
    display: block;
    padding: 0 5%;
    & svg {
      left: 3%;
    }
  }
  @media (max-width: 767px) {
    padding: 0 5%;
    & svg {
      left: 1%;
    }
  }
`
export const FirstProcess = styled.div`
  flex: 1;
  position: relative;
  border-bottom: 4px solid rgba(250, 183, 2, 0.2);
  padding: 0 15px 15px 40px;
  ${BreakpointUp.sm`		
		padding: 0 15px 30px 50px;
	`}
  ${BreakpointUp.xl`		
		padding: 0 15px 50px 0;
	`}
	& strong {
    margin: 0 0 15px;
    @media (max-width: 1199px) {
      margin: 0;
    }
  }
  &:hover {
    cursor: pointer;
    & strong {
      color: #fab702;
    }
  }
  @media (max-width: 1199px) {
    border-bottom: 0;
    border-left: 4px solid #fab702;
  }
`
export const ProcessNumber = styled.span`
  position: absolute;
  color: ${(props) => props.color};
  border: 4px solid ${(props) => props.borderColor};
  border-radius: 50%;
  text-align: center;
  background: white;
  font-family: 'UniNeueBold', sans-serif;
  font-size: 28px;
  color: #fab702;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 84px;
  bottom: -42px;

  @media (max-width: 1199px) {
    top: 0;
    height: 70px;
    width: 70px;
    left: -35px;
  }
  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
    left: -25px;
  }
`
export const OtherProcess = styled.div`
  flex: 1;
  position: relative;
  border-bottom: 4px solid rgba(250, 183, 2, 0.2);
  padding: 0 15px 15px 40px;
  ${BreakpointUp.sm`		
		padding: 0 15px 30px 50px;
	`}
  ${BreakpointUp.xl`		
		padding: 0 15px 50px 15px;
	`}
	& strong {
    margin: 0 0 15px;
    @media (max-width: 1199px) {
      margin: 0;
    }
  }
  &:hover {
    cursor: pointer;
    & strong {
      color: #fab702;
    }
  }
  @media (max-width: 1199px) {
    border-bottom: 0;
    border-left: 4px solid #fab702;
  }
`
import React from "react"
import styled from "styled-components"
import CloseIcon from "../Icons/CloseIcon"
import HelpIcon from "../Icons/HelpIcon"
import ArrowIcon from "../Icons/ArrowIcon"
import BreakpointUp from "../Media/BreakpointUp"
// import { navigate } from "gatsby-link"
// import encode from "../../utils/encode"

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 151;
  display: ${(props) => props.display};
  height: 100vh;
`

const Help = styled.div`
  position: absolute;  
  z-index: 102;
  cursor: pointer;
  top: 20px;
  right: 80px;
  ${BreakpointUp.xl`
    top: 30px;
    right: 100px;
  `}
  ${BreakpointUp.xxl`
    top: 40px;
  `}
`

const Close = styled.div`
  position: absolute;
  z-index: ${(props) => props.zIndex};
  cursor: pointer;
  width: 24px;
  height:24px;
  & svg {
    width: 24px;
    height:24px;
  }
  top: 30px;
  right: 30px;
  ${BreakpointUp.xl`
    top: 40px;
    right: 40px;
  `}
  ${BreakpointUp.xxl`
    top: 50px;
    right: 50px;
  `}
`
Close.defaultProps = {
  zIndex: "102",
}
const HelpBox = styled.div`
  position: fixed;
  z-index: 152;
  height: 100%;
  top: 0px;
  background: #0059b2;
  transform: ${(props) => props.ts};
  transition: transform ease 0.3s;
  overflow-y:auto;
  width: 470px;
  right: -470px;
  padding: 100px 30px 30px 30px;
  ${BreakpointUp.xl`
    padding: 150px 30px 30px;
  `}
  ${BreakpointUp.xxl`
    padding: 170px 50px 50px;
  `}
`
const HelpBoxTitle = styled.div`
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  color:#fff;
  margin-bottom:15px;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  
`
const HelpItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom:30px;
  .icon{
    & svg {
      width: 30px;
    }
    + .text{
      margin-left:10px;
    }
  }
  .text {
    font-size: 16px;
    line-height:28px;
    color: #fff;
    & a {
      color: #fab702;
      font-weight:700;
      cursor: pointer;
      &:hover{
        text-decoration: underline !important;
      }
    }
  }
`

const FormWrap = styled.form`
  display: flex;
  flex-wrap:wrap;
  margin:0;
  padding: 50px;

  @media(max-width: 600px) {
    padding: 50px 20px;
  }

  iframe {
    border: 0;
    width: 100%;
  }
`

// const FormTitle = styled.div`
//  color: #0059B2 !important;
// `
// const Label = styled.div`
//   margin-top: ${(props) => props.mt};
//   font-size: 16px;
//   line-height: 32px;
//   color: rgba(0, 0, 0, 0.5);
//   font-family: 'UniNeueBold', sans-serif;
//   font-weight: 700;
// `
// const RequestTypeTitle = styled.div`
//   color:#000;
// `
// const RequestType = styled.label`
//   border-radius: 30px;
//   border: 1px solid ${(props) => props.borderColor};
//   font-size: 14px;
//   line-height: 22px;
//   color: ${(props) => props.color};
//   background: ${(props) => props.bg};
//   width: fit-content;
//   margin: 5px;
//   padding: 10px 15px;
//   display: inline-block;
//   ${BreakpointUp.md`
//     display: block;
//     margin-top: 15px;
//     padding: 10px 20px;
//   `}
//   & :hover {
//     border: 1px solid #fab702;
//     background: #fab702;
//     color: white;
//     cursor: pointer;
//   }
//   & input[type="radio"] {
//     display: none !important;
//   }
// `
// const UrgencyType = styled.label`
//   margin-top: 15px;
//   display: block;
//   font-size: 14px;
//   line-height: 1;
//   width: fit-content;
//   & input[type="radio"] {
//     box-sizing: border-box;
//     padding: 0;
//     vertical-align: top;
//     margin-right: 5px;
//   }
// `

const InputFiled = styled.div`
  display: flex;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  justify-content: space-between;
  flex-direction:column;
  ${BreakpointUp.sm`
    flex-direction:row;
  `}
  & input {
    border: 0px;
    border-bottom: 1px solid #d6ecff;
    font-size: 16px;
    line-height: 36px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    padding: 15px 0;
    width: 100%;
    ${BreakpointUp.sm`
      width: 49%;
    `}
  }
  & textarea {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #d6ecff;
    font-size: 16px;
    line-height: 36px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    padding: 10px 0;
    display: block;
    resize: none;
  }
  & button {
    background: #fab702;
    color: white;
    font-size: 20px;
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 700;
    padding: 15px 60px;
    border: 0px;
    border-radius: 8px;
  }
`
InputFiled.defaultProps = {
  mt: "20px",
}

class RequestQuote extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openHelp: false,
    }
    this.closeMenu = this.closeMenu.bind(this)
    this.openHelpBox = this.openHelpBox.bind(this)
    this.closeHelpBox = this.closeHelpBox.bind(this)
  }
  closeMenu() {
    const { onClose } = this.props
    onClose()
  }
  
  openHelpBox() {
    this.setState({
      openHelp: true,
    })
  }
  closeHelpBox() {
    this.setState({
      openHelp: false,
    })
  }
  
  render() {
    const { display } = this.props
    const { openHelp } = this.state
    return (
      <Wrap display={display} className="modal">

        <Close onClick={this.closeMenu}><CloseIcon fill="#000" /></Close>
        <Help onClick={this.openHelpBox}><HelpIcon /></Help>
        <HelpBox ts={openHelp ? "translateX(-470px)" : "none"}>
          <Close onClick={this.closeHelpBox} zIndex="160"><CloseIcon fill="#fff" /></Close>
          <HelpBoxTitle>Once the form has been submitted : </HelpBoxTitle>
          <HelpItem>
            <span className="icon"><ArrowIcon fill="#FAB702" /></span>
            <span className="text">Our quoting department will begin working on your quote as quickly as possible.</span>
          </HelpItem>
          <HelpItem>
            <span className="icon"><ArrowIcon fill="#FAB702" /></span>
            <span className="text">You should receive your quote within 24-48 hours after you submit it.</span>
          </HelpItem>
          <HelpItem>
            <span className="icon"><ArrowIcon fill="#FAB702" /></span>
            <span className="text">If you have any questions, feel free to email <a href="mailto:sales@aesintl.com">sales@aesintl.com</a></span>
          </HelpItem>
          <HelpItem>
            <span className="icon"><ArrowIcon fill="#FAB702" /></span>
            <span className="text">or you can also give us a call. <a href="tel:8663861001">866-386-1001</a></span>
          </HelpItem>
        </HelpBox>
        <FormWrap>
          <iframe src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/6" title="Cognito Forms"></iframe>
        </FormWrap>
      </Wrap>
    )
  }
}

export default RequestQuote

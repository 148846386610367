import React from "react"
import styled from "styled-components"
import { SectionTitle } from "../../components/SectionTags";

const FormWrap = styled.div`
  iframe {
    border: 0;
    width: 100%;
    html .cog-abuse, :root:root:root:root:root .cog-abuse {
      display: none !important;
    }
  }
`

const RepairContactForm = () => {
  
  return (
    <FormWrap>
      <SectionTitle>Request a Quote</SectionTitle>
      <iframe
        src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/5"
        title="Cognito Forms"
      />
    </FormWrap>
  )
};

export default RepairContactForm

import React from 'react'

const CheckIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="26.451" height="18" viewBox="0 0 26.451 18">
	  <g id="tick_2_" data-name="tick (2)" transform="translate(-123.158 -165.364)">
	    <g id="Group_5961" data-name="Group 5961" transform="translate(123.158 165.364)">
	      <g id="Group_5960" data-name="Group 5960" transform="translate(0 0)">
	        <path id="Path_16800" data-name="Path 16800" d="M149.043,166.007a2.06,2.06,0,0,0-2.914-.077l-13.158,12.479-6.276-6.444a2.061,2.061,0,0,0-2.953,2.876l7.695,7.9a2.062,2.062,0,0,0,2.9.058l14.634-13.878A2.061,2.061,0,0,0,149.043,166.007Z" transform="translate(-123.158 -165.364)" fill={props.fill}/>
	      </g>
	    </g>
	  </g>
	</svg>
)

export default CheckIcon
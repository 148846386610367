import React from 'react'

const HelpIcon = (props) =>(
	<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><g fill={props.fill}><path d="M22 44a22 22 0 0 1-15.556-37.556 22 22 0 0 1 31.112 31.112 21.857 21.857 0 0 1-15.556 6.444zm0-41a19 19 0 1 0 19 19 19.021 19.021 0 0 0-19-19z" transform="translate(15635 18872) translate(-15635 -18872)"/><path d="M22 44c-5.876 0-11.401-2.288-15.556-6.444-4.155-4.155-6.444-9.68-6.444-15.557 0-5.876 2.288-11.401 6.444-15.556 4.155-4.155 9.68-6.444 15.556-6.444 5.877 0 11.402 2.288 15.557 6.444 4.155 4.155 6.444 9.68 6.444 15.556 0 5.877-2.288 11.402-6.444 15.557-4.155 4.155-9.68 6.444-15.557 6.444zm0-41c-10.477 0-19 8.523-19 19s8.523 19.001 19 19.001 19.001-8.524 19.001-19.001c0-10.477-8.524-19-19.001-19z"/><path d="M4.248-14.173h2.229a3.469 3.469 0 0 0 1.921-.44 1.73 1.73 0 0 0 .66-1.555v-.675a1.954 1.954 0 0 0-.758-1.73 4.037 4.037 0 0 0-2.292-.527 10.586 10.586 0 0 0-2.215.235 24.429 24.429 0 0 0-2.449.675v-3.725a11.084 11.084 0 0 1 6.219-1.731 6.7 6.7 0 0 1 4.825 1.643 5.733 5.733 0 0 1 1.712 4.339v1.584q0 5.691-6.1 5.691v2.405h-3.752zm.381 12.261a.634.634 0 0 1-.469-.176.634.634 0 0 1-.176-.469v-3.227q0-.675.645-.675h2.934q.7 0 .7.675v3.227q0 .645-.7.645z" transform="translate(15635 18872) translate(-15620.344 -18837.352)"/></g></svg>
)

HelpIcon.defaultProps = {
	fill: "#fab702"
}

export default HelpIcon
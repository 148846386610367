import React from "react"
import styled from "styled-components"
import CheckIcon from "../Icons/CheckIcon"
import { ProcessWrap, FirstProcess, ProcessNumber, OtherProcess } from "../ProcessStyle"
import BreakpointUp from "../Media/BreakpointUp"
import { Section, Container, SectionTitle } from "../SectionTags"

const ProcessTitle = styled.div`
  position:relative;
  z-index:1;
  & button {
    font-family: 'UniNeueBold', sans-serif;
    font-weight: 800;
    color: #000;
    text-decoration: underline !important;
    cursor: pointer;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (min-width: 991px) {
      font-size: 44px;
      line-height: 58px;
    }
    &:hover {
      text-decoration: none !important;
      color: #fab702;
    }
  }
  & h2 {
    max-width: 768px;
    width: 100%;
    color: #000;
    margin-bottom: 20px;
    ${BreakpointUp.lg`		
			margin-bottom:30px;
		`}
    ${BreakpointUp.xl`		
			margin-bottom:60px;
		`}
  }
`

class Process extends React.Component {
  render() {
    const { data } = this.props    
    return (
      <Section xpt="70px" xpb="140px" mpt="40px" mpb="80px" pt="40px" pb="40px">        
        <Container>
          {/* <ProcessTitle>
            <button
              type="button"
              aria-label="Request A Qoute"
              onClick={this.props.openRequest}
            >
              Request A Quote
            </button>{" "}
          </ProcessTitle> */}
          <SectionTitle>{data.secondTitle}</SectionTitle>
          <ProcessWrap>
            {data.features.map((item, i) => {
              if (i === 0) {
                return (
                  <FirstProcess key={i}>
                    <strong className="h3">{item.process}</strong>
                    <p>{item.description}</p>
                    <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                      {i + 1}
                    </ProcessNumber>
                  </FirstProcess>
                )
              } else {
                return (
                  <OtherProcess key={i}>
                    <strong className="h3">{item.process}</strong>
                    <p>{item.description}</p>
                    <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                      {i + 1}
                    </ProcessNumber>
                  </OtherProcess>
                )
              }
            })}
            <CheckIcon fill="#fab702" />
          </ProcessWrap>
        </Container>
      </Section>
    )
  }
}

export default Process
